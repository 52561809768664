<template>
  <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-sm-down">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <template v-if="isEdit">
            Edit Student
            <span class="text-secondary">{{ studentFullName }}</span>
          </template>
          <template v-else>
            Add Student
          </template>
        </h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          @click="close"
        />
      </div>

      <div class="modal-body">
        <NavTabs v-model="activeTab" :tabs="tabs" class="mb-3" />
        <StudentPersonalInfo
          v-if="activeTab == 'personalInfo'"
          v-model="student"
          :input-errors="inputErrors"
        />
        <StudentRegistrationData
          v-if="activeTab == 'registrationData'"
          :student-registration-id="student?.student_registration"
          hide-email-name
        />
        <StudentBilling
          v-if="activeTab == 'billing' && userStore.isCurrentTeacherAdmin"
          v-model="student"
          :input-errors="inputErrors"
        />
        <StudentAttendances
          v-if="activeTab == 'attendances'"
          v-model="student"
        />
        <StudentActiveClasses
          v-if="activeTab == 'activeClasses'"
          v-model="student"
        />
        <div
          v-if="inputErrors['non_field_errors'] || inputErrors['attendances']"
          class="alert alert-danger p-1 mb-0"
          role="alert"
        >
          {{ inputErrors["non_field_errors"] || inputErrors["attendances"] }}
        </div>
      </div>
      <div class="modal-footer">
        <div v-if="student?.id" class="me-auto">
          <button
            v-if="activeTab == 'personalInfo' && userStore.isCurrentTeacherAdmin"
            type="button"
            class="btn btn-danger"
            @click="confirmDeleteStudent"
          >
            Delete
          </button>
        </div>
        <button type="button" class="btn btn-secondary" @click="close">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" @click="save">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import Student from "@/types/models/student";
import { IInputErrors } from "@/types/input";
import { useStudentStore } from "@/stores/student.store";
import { useToastStore } from "@/stores/toast.store";
import StudentPersonalInfo from "@/components/modals/student/tabs/StudentPersonalInfo.vue";
import StudentBilling from "@/components/modals/student/tabs/StudentBilling.vue";
import StudentAttendances from "@/components/modals/student/tabs/StudentAttendances.vue";
import { useModalStore } from "@/stores/modal.store";
import NavTabs from "@/components/ui/nav_tabs/NavTabs.vue";
import StudentRegistrationData from "@/components/modals/student/tabs/StudentRegistrationData.vue";
import StudentActiveClasses from "@/components/modals/student/tabs/StudentActiveClasses.vue";
import { useUserStore } from "@/stores/user.store";

export default defineComponent({
  name: "StudentModal",
  components: {
    StudentActiveClasses,
    StudentPersonalInfo,
    StudentRegistrationData,
    StudentBilling,
    StudentAttendances,
    NavTabs,
  },
  mixins: [mappingsMixin],
  props: {
    studentProp: {
      type: Object as PropType<Student>,
      default: () => {
        return null;
      },
    },
  },
  emits: ["close"],
  setup() {
    const userStore = useUserStore();
    const studentStore = useStudentStore();
    const toastStore = useToastStore();
    const modalStore = useModalStore();
    const inputErrors = ref({} as IInputErrors);
    const student = ref<Student>();
    const activeTab = ref("personalInfo");

    return { userStore, studentStore, modalStore, toastStore, inputErrors, student, activeTab };
  },
  computed: {
    isEdit() {
      console.log("this.student: ", this.student);
      return this.student?.id != null;
    },
    tabs() {
      let tabs = [
        {
          key: "personalInfo",
          title: "Personal Info",
        },
      ]
      if (this.isEdit) {
        tabs.push(
          {
            key: "registrationData",
            title: "Registration Data",
          },
        )
        if (this.userStore.isCurrentTeacherAdmin) {
          tabs.push({
            key: "billing",
            title: "Billing",
          })
        }

        tabs = tabs.concat([
          {
            key: "attendances",
            title: "Attendances",
          },
          {
            key: "activeClasses",
            title: "Active Classes",
          },
        ]);
      }
      return tabs;
    },
    studentFullName() {
      const fullName = [this.student?.first_name, this.student?.last_name].join(" ");
      if (fullName) {
        return `(${fullName})`;
      }

      return "";
    },
  },
  mounted() {
    this.student = new Student({
      id: this.studentProp?.id,
      first_name: this.studentProp?.first_name,
      last_name: this.studentProp?.last_name,
      email: this.studentProp?.email,
      is_active: this.studentProp?.is_active,
      title: this.studentProp?.title,
      phone: this.studentProp?.phone,
      class_language: this.studentProp?.class_language,
      class_location: this.studentProp?.class_location,
      level: this.studentProp?.level,
      initial_course_passed: this.studentProp?.initial_course_passed,
      initial_course_notes: this.studentProp?.initial_course_notes,
      pause_until: this.studentProp?.pause_until,
      notes: this.studentProp?.notes,
      student_registration: this.studentProp?.student_registration,
      flexible_classes_count: this.studentProp?.flexible_classes_count,
      unused_classes_count: this.studentProp?.unused_classes_count,
      company: this.studentProp?.company,
    });

    // if (!this.student.id) {
    //   this.student.class_language = this.getDefaultChoice<string>("class_language") as string;
    //   this.student.class_location = this.getDefaultChoice<string>("class_location") as string;
    // }
  },
  methods: {
    async save() {
      if (!this.student) return;

      try {
        await this.studentStore.saveStudent(this.student);
      } catch (error: any) {
        console.error(error);
        this.inputErrors = error;
        return;
      }

      // Re-fetch students to update students table.
      await this.studentStore.getStudents();
      this.close();
    },
    confirmDeleteStudent() {
      if (!this.student || !this.userStore.isCurrentTeacherAdmin) {
        return;
      }
      this.modalStore.openDeleteStudentModal(this.student, this.deleteStudent);
    },
    async deleteStudent() {
      if (!this.student?.id) return;

      try {
        await this.studentStore.deleteStudent(this.student.id);
      } catch (error: any) {
        this.inputErrors = error;
        console.error(error);
        return;
      }

      // Re-fetch students to update students table.
      await this.studentStore.getStudents();
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
});
</script>
<style scoped lang="scss">
.modal-body {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.modal-content {
  min-height: 80vh;
}
@include sm {
  .modal-content {
    max-height: 90vh;
  }
  .modal-dialog.modal-xl {
    --bs-modal-width: 100%;
  }
}
@include lg {
  .modal-dialog.modal-xl {
    --bs-modal-width: 1340px;
  }
}
</style>
