<template>
  <form v-if="student" class="personal_info">
    <div class="container main-container g-0">
      <div class="row">
        <div class="col">
          <label for="first_name" class="col-form-label"> Name </label>
          <input
            id="first_name"
            v-model="student.first_name"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': inputErrors['first_name'] }"
          >
          <div class="invalid-feedback">
            {{ inputErrors["first_name"] }}
          </div>
        </div>
        <div class="col">
          <label for="last_name" class="col-form-label"> Surname </label>
          <input
            id="last_name"
            v-model="student.last_name"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': inputErrors['last_name'] }"
          >
          <div class="invalid-feedback">
            {{ inputErrors["last_name"] }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <label for="email" class="col-form-label">E-mail</label>
          <input
            id="email"
            v-model="student.email"
            type="email"
            class="form-control"
            :class="{ 'is-invalid': inputErrors['email'] }"
          >
          <div class="invalid-feedback">
            {{ inputErrors["email"] }}
          </div>
        </div>
        <div class="col">
          <label for="phone" class="col-form-label"> Phone </label>
          <input
            id="phone"
            v-model="student.phone"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': inputErrors['phone'] }"
          >
          <div class="invalid-feedback">
            {{ inputErrors["phone"] }}
          </div>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col">
          <label for="level" class="col-form-label"> Class Language </label>
          <SelectInput
            id="class_language"
            v-model="student.class_language"
            :options="getChoices('class_language')"
            :disable-search="true"
            :class="{ 'is-invalid': inputErrors['class_language'] }"
          />
          <div class="invalid-feedback">
            {{ inputErrors["class_language"] }}
          </div>
        </div>
        <div class="col">
          <label for="level" class="col-form-label"> Language Level </label>
          <SelectInput
            id="level"
            v-model="student.level"
            :options="getChoices('level')"
            :class="{ 'is-invalid': inputErrors['level'] }"
          />
          <div class="invalid-feedback">
            {{ inputErrors["level"] }}
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <label for="level" class="col-form-label"> Company </label>
          <SelectInput
            id="company"
            v-model="student.company"
            :options="getChoices('company')"
            :disable-search="true"
            :class="{ 'is-invalid': inputErrors['company'] }"
          />
          <div class="invalid-feedback">
            {{ inputErrors["company"] }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 col-sm-4">
          <label for="level" class="col-form-label"> Class Location </label>
          <SelectInput
            id="class_location"
            v-model="student.class_location"
            :options="getChoices('class_location')"
            :disable-search="true"
            :class="{ 'is-invalid': inputErrors['class_location'] }"
          />
          <div class="invalid-feedback">
            {{ inputErrors["class_location"] }}
          </div>
        </div>
        <div class="col-6 col-sm-2 checkbox-center">
          <label class="col-form-label"> Flexi Classes </label>
          <div class="form-check">
            <BooleanCheckCircle :is-true="student.flexible_classes_count > 0" />
          </div>
        </div>
        <div class="col-6 col-sm-3 checkbox-center">
          <label class="col-form-label" for="initial_course_passed"> Pause Until </label>
          <VueDatePicker
            v-model="student.pause_until"
            class="pause_until"
            input-class-name="form-control"
            placeholder="Select date"
            :format="formatDate"
            :enable-time-picker="false"
            hide-input-icon
            auto-apply
            :teleport="true"
            @click.stop
          />
        </div>
        <div class="col checkbox-center">
          <label class="col-form-label" for="initial_course_passed"> OLH Done </label>
          <div class="form-check">
            <input
              id="initial_course_passed"
              v-model="student.initial_course_passed"
              class="form-check-input d-block"
              type="checkbox"
            >
          </div>
        </div>
        <div class="col checkbox-center">
          <label class="col-form-label"> Is Active </label>
          <div class="form-check">
            <input v-model="student.is_active" class="form-check-input d-block" type="checkbox">
          </div>
        </div>
      </div>
      <div>
        <label for="initial_course_notes" class="col-form-label"> OLH Notes </label>
        <textarea
          id="initial_course_notes"
          v-model="student.initial_course_notes"
          class="form-control"
          rows="5"
        />
      </div>

      <hr>

      <div>
        <label for="notes" class="col-form-label">Notes</label>
        <textarea
          id="notes"
          v-model="student.notes"
          class="form-control"
          rows="3"
        />
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import SelectInput from "@/components/ui/input/SelectInput.vue";
import Student from "@/types/models/student";
import { IInputErrors } from "@/types/input";
import { defineComponent, PropType } from "vue";
import mappingsMixin from "@/mixins/mappings.mixin";
import BooleanCheckCircle from "@/components/ui/BooleanCheckCircle.vue";
import useDateMixin from "@/mixins/date.mixin";

export default defineComponent({
  name: "StudentPersonalInfo",
  components: { BooleanCheckCircle, SelectInput },
  mixins: [mappingsMixin],
  props: {
    modelValue: {
      type: Object as PropType<Student>,
      default: () => {
        return {};
      },
    },
    inputErrors: {
      type: Object as PropType<IInputErrors>,
      default: () => {
        return {};
      },
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const { formatDate } = useDateMixin();
    return { formatDate }
  },
  computed: {
    student: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
</script>

<style scoped lang="scss">
.personal_info {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .main-container {
    height: 100%;
    overflow: hidden auto;
    flex-grow: 1;
  }
  .row {
    row-gap: 1rem;
  }
}
</style>
