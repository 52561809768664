import IStudent from "@/types/student";
import User from "@/types/models/user";

export default class Student extends User implements IStudent {
  title;
  title_long;
  phone;
  level;
  class_language;
  class_location;
  initial_course_passed;
  initial_course_notes;
  notes;
  flexible_classes_count;
  unused_classes_count;
  pause_until;
  class_purchases;
  student_registration;
  company;

  constructor({
    id = undefined,
    first_name = "",
    last_name = "",
    email = "",
    is_active = true,
    title = "",
    title_long = "",
    phone = "",
    class_language = "",
    class_location = "",
    level = "",
    initial_course_passed = false,
    initial_course_notes = "",
    notes = "",
    created = "",
    flexible_classes_count = 0,
    unused_classes_count = 0,
    pause_until = undefined,
    student_registration = undefined,
    company = undefined,
    class_purchases = undefined,
  }: Partial<IStudent> = {}) {
    super({
      id,
      first_name,
      last_name,
      email,
      is_active,
      created,
    });
    this.title = title;
    this.title_long = title_long;
    this.phone = phone;
    this.class_language = class_language;
    this.class_location = class_location;
    this.level = level;
    this.initial_course_passed = initial_course_passed;
    this.initial_course_notes = initial_course_notes;
    this.notes = notes;
    this.flexible_classes_count = flexible_classes_count;
    this.unused_classes_count = unused_classes_count;
    this.pause_until = pause_until;
    this.class_purchases = class_purchases;
    this.student_registration = student_registration;
    this.company = company;
  }
  validate() {
    const errors = super.validate();
    // if (!this.level) errors["level"] = "Language level should not be empty.";
    // if (!this.class_language) errors["class_language"] = "Class language should not be empty.";
    // if (!this.class_location) errors["class_location"] = "Class location should not be empty.";
    return errors;
  }
}
